import React from "react"
import { Link, graphql } from "gatsby"
import Helmet from 'react-helmet'

import Bio from "../components/bio"
import Layout from "../components/layout"
import { rhythm } from "../utils/typography"

class BlogIndex extends React.Component {
	render() {
		const { data } = this.props
		const siteTitle = data.site.siteMetadata.title
		const posts = data.posts.edges
		const links = data.links.edges

		return (
			<Layout location={this.props.location} title={siteTitle}>
				<Helmet
					title={siteTitle}
				/>
				<Bio />
				<h2>Derniers articles (<Link to="posts">la liste complète</Link>)</h2>
				{posts.map(({ node }) => {
					const { path, title } = node.frontmatter
					return (
						<div key={node.frontmatter.path}>
							<h3
								style={{
									marginBottom: rhythm(1 / 4),
								}}
							>
								<Link style={{ boxShadow: `none` }} to={path}>
									{title}
								</Link>
							</h3>
							<small>{node.frontmatter.date}</small>
							<p
								dangerouslySetInnerHTML={{
									__html: node.frontmatter.description || node.excerpt,
								}}
							/>
						</div>
					)
				})}
				<p>
					<Link to="posts">Tous les articles</Link>
				</p>
				<h2>Dernières lectures d'Internet (<Link to="links">la liste complète</Link>)</h2>
				{links.map(({ node }) => {
					const { title, link } = node.frontmatter
					const htmlWithoutParagraph = node.html.replace(
						/^<p>(.*)<\/p>$/g,
						'$1'
					)
					return (
						<div key={title}>
							<p>
								<a
									style={{ boxShadow: 'none' }}
									href={link}
									target="_blank"
									rel="noopener noreferrer"
								>
									{title}
								</a>&nbsp;:&nbsp;
								<span
									dangerouslySetInnerHTML={{
										__html: htmlWithoutParagraph,
									}}
									style={{ p: { display: 'inline' } }}
								/>
							</p>
						</div>
					)
				})}
			</Layout>
		)
	}
}

export default BlogIndex

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    posts: allMarkdownRemark(
      filter: {
        frontmatter: { draft: { ne: true }, layout: { eq: "Post" } }
      }
      limit: 10
      sort: { fields: [frontmatter___date], order: DESC }
    ) {
      edges {
        node {
          excerpt
          frontmatter {
            path
            title
            date(locale: "fr-FR", formatString: "DD/MM/YYYY")
          }
        }
      }
    }
    links: allMarkdownRemark(
      filter: {
        frontmatter: { draft: { ne: true }, layout: { eq: "Link" } }
      }
      limit: 10
      sort: { fields: [frontmatter___date], order: DESC }
    ) {
      edges {
        node {
          frontmatter {
            title
            link
            date(locale: "fr-FR", formatString: "DD/MM/YYYY")
          }
          html
        }
      }
    }
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            date(locale: "fr-FR", formatString: "DD/MM/YYYY")
            title
          }
        }
      }
    }
  }
`
